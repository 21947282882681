<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h5>Support Tickets</h5>
        </b-col>

        <!-- Search -->
        <b-col cols="8">
          <b-row class="d-flex align-items-center justify-content-end">
            <b-col cols="6" lg="4">
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
            <generate-ticket @refetch-data="refetchData"></generate-ticket>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-overlay
      :show="$store.state.ticketStore.ticketLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchTickets"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Balance -->
        <template #cell(datetime)="data">
          <div>
            {{ getDate(data.item.datetime) }}
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(status)="data">
          <template v-if="data.item.status === 'PENDING'">
            <b-badge pill variant="primary"> {{ data.item.status }} </b-badge>
          </template>
          <template v-else>
            <b-badge pill variant="info"> {{ data.item.status }} </b-badge>
          </template>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BModal,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import GenerateTicket from './GenerateTicket.vue'

// import store from '@/store'
import useSupportTicket from './useSupportTicket'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCardText,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    GenerateTicket,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple
  },
  methods: {
    getDate(date) {
      return moment(date).format('lll')
    }
  },

  setup(props) {
    let userData = JSON.parse(localStorage.getItem('userData'))
    const statusOptions = [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Resolved', value: 'RESOLVED' }
    ]

    const {
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useSupportTicket({ type: props.type })

    return {
      userData,
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
