<template>
  <div>
    <b-button v-b-modal.modal-generate-ticket variant="primary"> New </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-generate-ticket"
      title="Generate Ticket"
      no-close-on-backdrop
      centered
      ok-title="submit"
      :hide-footer="true"
      cancel-variant="outline-secondary"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Message -->
          <validation-provider
            #default="validationContext"
            name="Message"
            rules="required"
          >
            <b-form-group label="Message" label-for="message">
              <b-form-textarea
                id="message"
                v-model="blankData.message"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                rows="3"
                placeholder="Message..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Generate
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-generate-ticket')"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      // Message
      message: null,
      // validation rules
      required
    }
  },
  methods: {
    onSubmit() {
      console.log(this.blankData)
      store
        .dispatch('ticketStore/saveTicket', { payload: this.blankData })
        .then((response) => {
          console.log('FDF', response)
          if (response) {
            this.$bvModal.hide('modal-generate-ticket')
            this.$emit('refetch-data')
            this.$swal({
              icon: 'success',
              title: 'Ticket Generated',
              text: 'Thanks You, We will try to reach shortly!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Failed to Submit',
              text: 'Please try again!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })
    }
  },
  setup(props, context) {
    const blankData = {
      message: ''
    }

    const ticketData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      ticketData.value = JSON.parse(JSON.stringify(blankData))
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData)

    return {
      blankData,

      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
